<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<crud-table ref="listusers"
					:entities="entities"
					:meta="metaTable"
					@tableAction="onTableAction"
					@itemAction="onItemAction" />
			</div>
		</div>
		<DialogFormUser :entity="currentEntity" :visible="showEntityDialog"
			@hide="showEntityDialog=false"
			@cancel="showEntityDialog=false"
			@save="saveEntity($event)"/>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import {Service} from '@/service/common'
import CrudTable from '@/pages/common/CrudTable'
import DialogFormUser from './DialogFormUser'
import formatter from '@/utils/formatter'
import Consts from '@/utils/consts'
export default {
	components: {CrudTable, DialogFormUser},
	data() {
		return {
			entities: [],
			currentEntity: {},
			showEntityDialog: false,
			filters: {},
			metaTable: {
				entityName: "user",
				expander: true,
				searchable: true,
				paginator:{
					enable: true,
					rows: 10,
					currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} warehouse receipt",
				},
				columns: [
					{
						name: "email",
						label: "user.email",
						headerStyle: "width:15%; min-width:10rem;"
					},
					{
						name: "fullName",
						label: "user.fullName",
						headerStyle: "width:20%; min-width:10rem;"
					},
					{
						name: "roles",
						label: "user.roles",
						headerStyle: "width:10%; min-width:10rem;"
					},
					{
						name: "lastLogined",
						label: "user.lastLogined",
						headerStyle: "width:10%; min-width:12rem;",
						formatter: (date)=> formatter.formatDate(date, Consts.FORMAT_DATE_TIME_US)
					},
					{
						name: "description",
						label: "user.description",
						headerStyle: "width:30%; min-width:10rem;"
					}
				],
				tableActions: [
					{
						name: "create",
						icon: "pi pi-create",
						style: "p-button-rounded p-button-success mr-2"
					}
				],
				itemActions: [
					{
						name: "edit",
						icon: "pi pi-pencil",
						style: "p-button-rounded p-button-success mr-2",
						condition: "mode != 'edit'"
					},
					{
						name: "save",
						icon: "pi pi-save",
						style: "p-button-rounded p-button-success mr-2",
						condition: "mode == 'edit'"
					},
					{
						name: "delete",
						icon: "pi pi-trash",
						style: "p-button-rounded p-button-warning mt-2",
						condition: "mode != 'edit'"
					}
				]
			}
		}
	},
	created() {
		this.initFilters();
	},
	mounted() {
		this.service = new Service('users');
		this.service.getAll().then(data => {
			this.entities = data;
		});
	},
	methods: {
		onTableAction(sAction) {
			if(sAction == 'create') {
				this.showEntityDialog = true
			}
		},
		onItemAction(sAction, entity) {
			this.currentEntity = entity
			this.showEntityDialog = true
		},
		saveEntity(entity) {
			if (entity.id) {
				this.entities[this.findIndexById(entity.id)] = entity;
					this.service.update(entity.id, entity).then((response) => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.updated'), life: 3000});
						this.currentEntity = response.data;
						this.showEntityDialog = false;
					})
			}
			else {
				this.service.create(entity).then((response) => {
					this.currentEntity = response.data;
					this.entities.push(response.data);
					this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.created'), life: 3000});
					this.showEntityDialog = false;
				})
			}
			this.selectedEntity = {};

		},
		editEntity(entity) {
			this.currentEntity = {...entity};
			this.showEntityDialog = true;
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.entities.length; i++) {
				if (this.entities[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		/*
		openConfirmDialog(event) {
				const entityName = this.$t('partner.entityName')
				console.log(entityName)
        this.$confirm.require({
						target: event.currentTarget,
            message: "Are you sure you want to proceed?",
            header: "Confirmation",
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.$toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
            },
            reject: () => {
								this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
						}
        });
    },

		confirmDeleteEntity(entity) {
			this.currentEntity = entity;
			//this.deleteEntityDialog = true;
			const entityName = this.$t('partner.entityName')
			this.$confirm.require({
					message: this.$t('common.confirm_delete_template', [entityName, this.currentEntity.name]),
					header: this.$t('common.confirm_delete'),
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
							this.deleteEntity()
							//callback to execute when user confirms the action
					},
					reject: () => {}
			});
		},
		deleteEntity() {
			this.entities = this.entities.filter(val => val.id !== this.currentEntity.id);
			this.service.delete(this.currentEntity.id).then(() => {
				this.deleteEntityDialog = false;
				this.currentEntity = {};
				this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('partner.deleted'), life: 3000});
			});
		},

		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteEntitiesDialog = true;
		},
		deleteSelectedEntities() {
			this.entities = this.entities.filter(val => !this.selectedEntities.includes(val));
			this.deleteEntitiesDialog = false;
			this.selectedEntities = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.multiple_deleted'), life: 3000});
		},
		*/
		initFilters() {
        this.filters = {
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
    }
	}
}
</script>

<style scoped lang="scss">
@import '@/assets/demo/badges.scss';
</style>
