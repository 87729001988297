<template>
  <Dialog :visible="containerVisible" :style="{width: '600px'}" :header="$t('user.dialog.form_header')" :modal="true" class="p-fluid" @update:visible="updateVisible">
    <div class="field grid">
      <label for="email" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.email')}}</label>
      <FormField :message="errors.email" class="col-12 md:col-10">
        <InputText ref="email" id="email" v-model.trim="formValues.email" :required="true" :disabled="!!formValues.id" autofocus />
      </FormField>
    </div>
    <div class="field grid">
      <label for="fullName" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.fullName')}}</label>
      <FormField :message="errors.fullName" class="col-12 md:col-10">
        <InputText ref="fullName" id="fullName" v-model.trim="formValues.fullName" :required="true" autofocus />
      </FormField>
    </div>
    <div class="field grid">
      <label for="password" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.password')}}</label>
      <FormField :message="errors.password" class="col-12 md:col-10">
        <InputText ref="password" id="password" v-model.trim="formValues.password" :required="true" autofocus type="password"/>
      </FormField>
    </div>
    <div class="field grid">
      <label for="password2" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.password2')}}</label>
      <FormField :message="errors.password2" class="col-12 md:col-10">
        <InputText ref="password2" id="password2" v-model.trim="formValues.password2" :required="true" autofocus type="password"/>
      </FormField>
    </div>
    <div class="field grid">
      <label for="description" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.description')}}</label>
      <div class="col-12 md:col-10">
        <Textarea id="description" v-model="formValues.description" required="false" rows="3" cols="20" />
      </div>
    </div>
    <template #footer>
      <Button :label="$t('button.cancel')" icon="pi pi-times" class="p-button-text" @click="onCancel"/>
      <Button :label="$t('button.save')" icon="pi pi-check" class="p-button-text" @click="onSave" />
    </template>
  </Dialog>
</template>

<script>
import ConfigService from '@/service/ConfigService';
import PartnerService from '@/service/PartnerService';
import FormField from '@/components/FormField'
import form from '@/mixins/form'
export default {
  name:'form-user',
  mixins: [form],
  components: {FormField},
  props: {
    entity: {
      type: Object,
      default() {
        // default function receives the raw props object as argument
        return {  }
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits:['cancel','save','hide'],
  data() {
    return {
      submitted: false,
      formValues: this.entity,
      containerVisible: this.visible,
      roles: [],
      partnerTypes: [],
      organizations: [],
      selectedPartnerType:null,
      selectedRole: null,
      selectedOrganization: null,
      errors: {},
      meta: {
        entityName: 'user'
      }
    }
  },
  methods: {
    //Get organizations list base on selected an orgType
    onChangeRole(event) {
      this.formValues.roles = event.value.value || null
    },
    onChangeOrgType(event) {
      this.formValues.orgType = event.value.value
      this._loadOrganizations();
    },
    _loadOrganizations() {
      if (this.formValues.orgType == 'agent') {
        this.organizations = this.agents;
      }
    },
    onChangeOrganization(event) {
      this.formValues.organization = event.value.code;
      this.formValues.email = event.value.contactEmail;
      this.formValues.fullName = event.value.contactName;
      this.formValues.roles = ConfigService.getAdminRole();
    },
    onCancel() {
      this.containerVisible = false;
      this.formValues = {};
      this.$emit('cancel');
    },
    validateForm() {
      let errors = {};
      if (this.formValues.password2 != this.formValues.password) {
        errors.password2 = this.$t('user.password2_not_matched');
      }
      return errors;
    },
    /*
    onSave() {
      this.submitted = true;
      if(!this.submitted) {
        this.errors = this.validate(this.formValues, 'user');
        if(Object.keys(this.errors).length == 0) {
          //validate password
          if (this.formValues.password2 == this.formValues.password) {
            this.submitted = true;
            this.$emit('save', this.formValues);
          }
        }
      }
    },
    */
    updateVisible(visible) {
      this.containerVisible = visible;
      this.$emit('hide')
    }
  },
  mounted() {
    PartnerService.getAgents().then(response => {
      this.agents = response;
    });
    this.partnerTypes = ConfigService.getPartnerTypes().map(type => ({
      label: this.$t('configs.partner_type.' + type),
      value: type
    }));

    this.roles = ConfigService.getRoles().map((role) => ({
        label: this.$t('configs.roles.' + role),
        value: role
      }));
  },
  updated() {
    this.submitted = false;
    this.formValues = this.entity;
    this.containerVisible = this.visible;
    this.selectedPartnerType = null;
    for(let i = 0; i < this.partnerTypes.length; i++) {
      if(this.formValues.orgType == this.partnerTypes[i].value) {
        this.selectedPartnerType = this.partnerTypes[i];
        break;
      }
    }

    this._loadOrganizations();
    this.selectedOrganization = null;
    for(let i = 0; i < this.organizations.length; i++) {
      if(this.organizations[i].code == this.formValues.organization) {
        this.selectedOrganization = this.organizations[i];
      }
    }
  },
}
</script>
